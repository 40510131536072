<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center" style="height: 80vh">
          <v-card
            v-for="room in rooms"
            :key="room.room_id"
            class="ma-3 pa-6 text-center"
            min-width="30%"
          >
            <v-text-field
              label="Room Name"
              outlined
              :key="room.room_id"
              v-model="room.room_name"
              hide-details
              dense
              append-icon="mdi-check-circle"
              @click:append="updateRoomName(room.room_id,room.room_name)"
            ></v-text-field>
            <v-card-actions class="px-0 mt-4">
              <v-btn color="secondary" dark block :to="'/room/'+room.room_id">
                Room Settings
                <v-icon dark right>mdi-arrow-right</v-icon>
              </v-btn>
              <v-icon></v-icon>
            </v-card-actions>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
 
import { mapMutations } from "vuex";
export default {
  name: "AddVendor",
  data() {
    return {
      rooms: [],
      dialog: false,
      loading: false,
      data: {},
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getRoomList() {
      let _self = this;
      this.$axios.get(`admin/room/list`)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.rooms = response_data.data;
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    updateRoomName(room_id, room_name) {
      console.log(room_id, room_name);
      let _self = this;
      this.loading = "secondary";
      this.$axios.post(`admin/room/edit`, {
        room_id: room_id,
        room_name: room_name,
      })
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getRoomList();
  },
};
</script>